<template>
  <div class="sound-player w-100" v-if="soundFile">
    <sound-wave
      class="mb-4"
      v-model="progress"
      :soundFile="soundFile"
      :initialMarkers="initialMarkers"
      :markers="markers"
      @ready="onSoundWaveReady"
      @statusChange="status = $event"
      @regionUpdated="$emit('regionUpdated', $event)"
      @regionRemoved="$emit('regionRemoved', $event)"
    >
    </sound-wave>
    <div class="controls">
      <div class="row align-items-center">
        <div
          class="col-4 d-flex justify-content-start"
          :class="{ 'col-12': !$slots.actions }"
        >
          <b-button size="sm" variant="primary" @click="onPlayPause">
            <span v-if="status === 'paused'">
              <i class="fa fa-play fa-lg" />
            </span>
            <span v-else-if="status === 'playing'">
              <i class="fa fa-pause fa-lg" />
            </span>
          </b-button>
          <b-button
            size="sm"
            class="px-2 py-1 mx-2"
            variant="primary"
            @click="download"
            :disabled="downloadLoading"
          >
            <i v-if="!downloadLoading" class="fa fa-download fa-lg" />
            <i v-else class="fa fa-spinner fa-spin fa-lg" />
          </b-button>
          <p class="mx-4 my-0">
            <br />
            {{ moment.utc(progress * 1000).format('HH:mm:ss') }} /
            {{ moment.utc(duration * 1000).format('HH:mm:ss') }}
          </p>
        </div>
        <div class="col-8 d-flex justify-content-end" v-if="$slots.actions">
          <slot name="actions"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SoundWave from '../elements/SoundWave'
import moment from 'moment'

export default {
  data() {
    return {
      progress: 0,
      duration: 0,
      waveSurfer: null,
      downloadLoading: false,
      status: 'paused'
    }
  },
  props: {
    soundFile: {
      type: String,
      required: true
    },
    initialMarkers: {
      type: Array,
      required: false,
      default: () => []
    },
    markers: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  watch: {
    progress(value) {
      this.$emit('progressChange', value)
    },
    status(value) {
      this.$emit('statusChange', value)
    }
  },
  computed: {
    moment() {
      return moment
    }
  },
  mounted() {},
  methods: {
    onSoundWaveReady({ waveSurfer, waveSurferRegions }) {
      this.waveSurfer = waveSurfer
      this.$emit('ready', {
        waveSurfer,
        waveSurferRegions,
        duration: waveSurfer.duration,
        progress: 0,
        status: 'paused'
      })

      this.duration = waveSurfer.duration || waveSurfer.decodedData.duration
    },
    onPlayPause() {
      this.waveSurfer && this.waveSurfer.playPause()
    },
    async download() {
      try {
        this.downloadLoading = true
        const response = await fetch(this.soundFile)
        const blob = await response.blob()
        const url = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.href = url
        link.download = this.soundFile.split('/').pop() || 'audio.mp3'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        URL.revokeObjectURL(url)
      } catch (error) {
        const link = document.createElement('a')
        link.href = this.soundFile
        link.setAttribute(
          'download',
          this.soundFile.split('/').pop() || 'audio.mp3'
        )
        link.setAttribute('target', '_blank') // Ensures it doesn't navigate away
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } finally {
        this.downloadLoading = false
      }
    }
  },
  components: {
    SoundWave
  }
}
</script>

<style lang="scss" scoped>
// .sound-wave {
//   cursor: cell;
//   border: 3px solid #008dcf;
//   padding: 8px 16px;
//   border-radius: 8px;
//   background: #008dcf1f;
//   background: linear-gradient(180deg, #008dcf00 0%, #008dcf2f 100%);
// }
</style>
